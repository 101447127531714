.app__header {
  display: flex;
  flex-direction: row;
  background: #fff;
  padding: 2rem;
}

.app__header-content {
  flex: .5;
  display: flex;
  justify-content: center;
  align-items: flex-start;
  flex-direction: column;

  margin-right: 5rem;
}

.app__header-content h1 {
  color: #54A1D0;
  font-family: var(--font-family);
  font-weight: 800;
  font-size: 62px;
  line-height: 70px;
  letter-spacing: -0.04em;
}

.app__header-content p {
  color: #262531;
  font-family: var(--font-family);
  font-weight: 800;
  font-size: 18px;
  line-height: 30px;
  margin: 1rem 0;
}

.app__header-image {
  flex: 1;
  display: flex;
  flex-direction: column;
}

.app__header-image img {
  max-width: 100%;
  max-height: 100%;
}

@media screen and (max-width:1500px) {
  .app__header {
    flex-direction: column;
  }  
}

@media screen and (max-width:550px) {
  .app__header {
    padding: 0 1rem;
  }  
}